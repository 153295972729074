import { Box, Container, Typography } from "@material-ui/core"
import { graphql } from "gatsby"
import _ from "lodash"
import React from "react"
import { DigicLink } from "../components/digic-link"
import Layout from "../components/layout/layout-home"
import HunIcon from "../images/positions/hungarian.svg"
import Freelance from "../images/positions/freelance.svg"
import Top from "../images/positions/top.svg"

export default function OpenPositions({ data, pageContext }) {
  const { lang } = pageContext
  const { openPositions } = data.strapi
  const groupedOpenPositions = _.groupBy(
    openPositions,
    openPosition => openPosition.department.name
  )
  return (
    <Layout>
      <Box pt="100px" minHeight="90vh">
        <Box py={4} mb={5} borderBottom={`2px solid #F26522`}>
          <Container>
            <Typography variant="h1">Open positions</Typography>
          </Container>
        </Box>
        <Container>
          <Box minHeight="40vh">
            {Object.keys(groupedOpenPositions).map(department => (
              <Box mb={5}>
                <Box mb={2}>
                  <Typography variant="h3" color="primary">
                    {department}
                  </Typography>
                </Box>
                {groupedOpenPositions[department].map(openPosition => (
                  <Box display="flex" alignItems="center" mb={1}>
                    <DigicLink
                      to={`/open-positions/${openPosition.id}/${lang}`}
                    >
                      <Typography variant="h2">{openPosition.name}</Typography>
                    </DigicLink>
                    {openPosition.open_position_tags.map(tag => (
                      <Box ml={2} alignItems="center" display="flex">
                        <img
                          src={tag.icon.imageFile.publicURL}
                          alt={tag.name_en}
                        ></img>
                      </Box>
                    ))}
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
          <Box my={8}>
            <Box display="flex" flexDirection="row" alignItems="center" my={2}>
              <img src={HunIcon} alt="hungarian required" />
              <Typography variant="h4" style={{ marginLeft: "16px" }}>
                HUNGARIAN LANGUAGE REQUIRED
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" my={2}>
              <img
                src={Top}
                style={{ width: "64px", height: "32px" }}
                alt="top priority"
              />
              <Typography variant="h4" style={{ marginLeft: "16px" }}>
                TOP PRIORITY
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" my={2}>
              <img
                src={Freelance}
                alt="freelance position"
                style={{ width: "64px", height: "32px" }}
              />
              <Typography variant="h4" style={{ marginLeft: "16px" }}>
                FREELANCE POSITIONS
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query OpenPositionsPageQuery($lang: String!) {
    strapi {
      openPositions(where: { language: $lang }) {
        id
        name
        language
        department {
          name
        }
        open_position_tags {
          name_hu
          icon {
            url
            imageFile {
              publicURL
            }
          }
        }
      }
    }
  }
`
